<template>
    <div>
      <head-slot>
        <title>Afghanistan Cricket Board | Users</title>
      </head-slot>
      print mode
    </div>
</template>
<script>
export default {
  name: 'AppPrint',
  layout: 'print',
}
</script>
<style>

</style>